.__footer {
  background-color: #44545c;
  width: 100%;
  // padding-top: 31px;
  // margin-top: 75px;

  .__container {
    width: 1300px;
    height: 100%;
    margin: 0 auto;

    .__schedules_details {
      border-bottom: solid 1px rgba(154, 155, 151, 1);
      // padding: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px 0;

      @media only screen and (max-width: 1100px) {
        flex-direction: column;
        padding: 0;
        padding-bottom: 10px;
      }

      .__logo {
        width: 111px;
        height: 155px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(251deg)
          brightness(102%) contrast(102%);
        // margin-right: 140px;
        margin-right: 50px;

        @media only screen and (max-width: 1100px) {
          margin: 0 !important;
          padding-top: 30px;
        }
      }

      .__details {
        text-align: center;
        // width: 407px;
        // color: #fff;
        color: rgba(255, 255, 255, 0.718);

        margin-left: 50px;
        @media only screen and (max-width: 1100px) {
          margin: 0 !important;
          padding-top: 50px;
        }

        & ul {
          list-style-type: none;
          text-align: left;

          @media only screen and (max-width: 1100px) {
            text-align: center;
          }

          & li {
            font-family: "Plus Jakarta Sans";
            font-size: 11px;
            margin-bottom: 9px;

            & p {
              color: rgba(154, 155, 151, 1);
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1400px) {
      padding-left: 50px;
      padding-right: 50px;
      width: 80vw;
    }
  }

  .__row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(154, 155, 151, 1);
    padding-bottom: 39px;
    padding-top: 60px;

    .__lcs_para {
      color: rgba(187, 179, 156, 1);
      width: 170px;
      line-height: 17px;
      margin-top: 15px;
      font-family: "Plus Jakarta Sans";
      font-size: 12px;
    }

    ul {
      list-style: none;

      li {
        // color: #fff;
        color: rgba(255, 255, 255, 0.718);
        margin-bottom: 11px;
        font-size: 11px;
        cursor: pointer;

        &:hover {
          color: rgba(118, 104, 57, 1);
        }

        &:last-child {
          margin: 0;
        }

        & a {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    h6 {
      font-family: "Plus Jakarta Sans";
      font-size: 8px;
      color: rgba(187, 179, 156, 1);
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    .__col_4 {
      & p {
        text-transform: uppercase;
        font-size: 8px;
        color: rgba(154, 155, 151, 1);
        margin-bottom: 10px;
      }
    }

    .__col_5 {
      .__socials {
        & img {
          width: 16px;
          margin-right: 16px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .__copyright {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 32px;

    .__links {
      // color: #fff;
      color: rgba(255, 255, 255, 0.718);

      display: flex;
      font-family: "Plus Jakarta Sans";
      font-size: 11px;

      & a {
        margin-right: 12px;
        cursor: pointer;
        font-weight: bold;
        word-spacing: 3px;

        @media only screen and (max-width: 1100px) {
          margin-bottom: 10px;
          margin-right: 0;
        }

        &:hover {
          color: rgba(187, 179, 156, 1);
        }
      }

      & span {
        margin-right: 10px;

        @media only screen and (max-width: 1100px) {
          display: none;
        }
      }

      & p {
        font-family: "Plus Jakarta Sans";
        font-size: 11px;
        // color: #fff;
        color: rgba(255, 255, 255, 0.718);

        font-weight: bold;
        cursor: pointer;

        @media only screen and (max-width: 1100px) {
          margin-top: 20px;
        }

        &:hover {
          color: rgba(187, 179, 156, 1);
        }
      }

      @media only screen and (max-width: 1100px) {
        flex-direction: column;
      }
    }

    .__copyright_text {
      color: rgba(255, 255, 255, 0.718);
      font-size: 9px;

      a{
        color: rgba(255, 255, 255, 0.718);
        text-decoration: none;

        &:hover{
          color: #ffffff;
        }
      }

      @media only screen and (max-width: 1100px) {
        margin-top: 50px;
      }
    }

    @media only screen and (max-width: 1100px) {
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
      text-align: center;
    }
  }
}
