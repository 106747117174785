// SIDEBAR
.__sidebar {
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #44545c;
  z-index: 1000;
  -webkit-transition: all 0.5s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 0.5s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  left: -400px;

  & img {
    width: 30px;
    position: absolute;
    left: 1.1em;
    top: 0.9em;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg)
      brightness(106%) contrast(100%);
    cursor: pointer;
    &:hover {
      filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
        brightness(100%) contrast(85%);
    }
  }

  .__nav_links {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    margin-top: 10em;

    & a {
      text-decoration: none;
      color: #fff;
      font-size: 30px;
      width: fit-content;
      // text-transform: uppercase;
      font-family: "Inria Serif";
      margin-bottom: 0.5em;
      cursor: pointer;
      letter-spacing: 0.1em;
      transition: 0.3s;

      &:hover {
        color: rgba(118, 104, 57, 1);
      }
    }

    .__divider {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.289);
      margin: 0.5em 0;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 280px;
  }
}

.__show {
  left: 0;
}

.__hide {
  left: -400px;
}

// HEADER
.__header {
  // height: 100px;
  width: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(68, 84, 92, 0.704);
  padding: 15px 25px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  transition: 1s ease;

  .__details {
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .__burger {
      width: 25px;
      cursor: pointer;

      &:hover {
        filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
          brightness(100%) contrast(85%);
      }
    }

    .__logo {
      width: 100px;
      cursor: pointer;
      transition: 0.5s ease;
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      &:hover {
        filter: brightness(250%);
      }
    }

    .__contact {
      cursor: pointer;
      &:hover {
        filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
          brightness(100%) contrast(85%);
      }
    }
  }
}

.__rm_header {
  backdrop-filter: inherit;
  background-color: inherit;
}

.__hide_logo {
  opacity: 0;
  pointer-events: none;
}
