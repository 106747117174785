.__rows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  position: relative;

  & img {
    position: absolute;
    right: -12em;
    top: -7em;
    filter: invert(90%) sepia(2%) saturate(844%) hue-rotate(341deg)
      brightness(112%) contrast(89%);

    opacity: 0.08;

    @media only screen and (max-width: 930px) {
      right: -2em;
      top: -11em;
    }
  }

  .__column {
    width: 250px;

    & h6 {
      margin-bottom: 20px;
      text-transform: uppercase;
      white-space: nowrap;

      @media only screen and (min-width: 930px) {
        text-align: center;
      }
    }

    & p {
      color: #766839;
    }

    @media only screen and (max-width: 1300px) {
      margin-bottom: 2em;
    }

    @media only screen and (max-width: 930px) {
      width: 300px;
    }
    @media only screen and (max-width: 350px) {
      width: 90%;
    }
  }

  @media only screen and (max-width: 1400px) {
    padding: 50px 50px 0 50px;
  }

  @media only screen and (max-width: 930px) {
    flex-direction: column;
    justify-content: inherit;
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
    align-items: flex-start;
  }
}
