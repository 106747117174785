.__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000060;
  overflow: hidden;
}

.__modal {
  width: 80vw;
  height: 80vh;
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding-bottom: 100px;
  border-radius: 8px;
  overflow-x: hidden;
  -webkit-box-shadow: 3px 0px 19px -1px #000000;
  box-shadow: 3px 0px 19px -1px #000000;

  &::-webkit-scrollbar {
    display: none;
  }

  .__head {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    position: sticky;
    background-color: rgba(255, 255, 255, 0.701);
    backdrop-filter: blur(10px);
    top: 0;
    z-index: 100;
  }

  & img {
    width: 25px;
    height: 25px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
        brightness(100%) contrast(85%);
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100vw;
    height: 100vh;
    padding-left: 0;
    border-radius: 0;
  }
}

.__container {
  padding-left: 50px;
  overflow-x: hidden;
}
