.__hero_banner {
  background: url("../../../public/images/home_bg.webp");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  & img {
    width: 150px;
    position: absolute;
    top: 3em;
    left: 50%;
    transform: translate(-50%);
  }

  .__details {
    // position: absolute;
    color: var(--main-text);
    text-align: left;
    // width: 700px;
    padding: 0 40px 5em 40px;

    .__para {
      padding-left: 10px;
      transform: translateY(-30px);

      @media only screen and (max-width: 600px) {
        padding-left: 3px;
      }

      @media only screen and (max-height: 450px) {
        transform: translateY(55px);
      }
    }

    @media only screen and (max-width: 600px) {
      padding-bottom: 50px !important;
    }

    & h1 {
      margin-bottom: 40px;

      @media only screen and (max-width: 600px) {
        font-size: 40px;
        line-height: 40px;
      }

      @media only screen and (max-height: 450px) {
        font-size: 40px;
        transform: translateY(100px);
      }
    }

    .__scroll_to_section {
      display: flex;
      align-items: center;
      border-bottom: solid 2px #fff;
      width: fit-content;
      padding-bottom: 5px;
      cursor: pointer;

      @media only screen and (max-height: 450px) {
        // transform: translateY(60px);
        margin-top: 60px;
      }

      &:hover {
        transform: scale(1.02);
      }

      &:active {
        opacity: 0.5;
      }
      & p {
        font-family: "Plus Jakarta Sans";
        font-weight: bolder;
        word-spacing: 2px;
        padding-left: 10px;
        cursor: pointer;
      }

      & img {
        position: relative;
        width: 15px;
        height: 15px;
        top: 0;
        left: 0;
        margin-left: 20px;
        transform: rotate(90deg);
      }
    }

    @media only screen and (max-width: 600px) {
      padding: 0;
      padding-left: 10px;
      padding-bottom: 5em;
      padding-right: 10px;
    }
  }

  @media only screen and (max-width: 930px) {
    background-position: 80% 75% !important;
  }

  @media only screen and (max-width: 830px) {
    background-position: 70% 75% !important;
  }

  @media only screen and (max-width: 710px) {
    background-position: 60% 75% !important;
  }
}
