.__testimonials {
  height: 474px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  margin-top: 88px;
  background-image: var(--gradient-bg-second);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--gradient-bg);
    opacity: 0.2;
    z-index: -1;
  }

  .__bg {
    mix-blend-mode: soft-light;
    object-fit: cover;
    max-width: 100%;

    @media only screen and (max-width: 1320px) {
      width: 100%;
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .__container {
    color: #fff;
    width: 900px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: flex-end;

    @media only scren and (max-width: 1320px) {
      width: fit-content !important;
      margin: 0 !important;
      justify-content: center !important;
    }

    .__details {
      width: 390px;
      // margin-top: 150px;

      @media only screen and (max-width: 1700px) {
        transform: translateX(10em);
      }

      @media only screen and (max-width: 1320px) {
        transform: inherit;
        margin-top: 230px;
      }

      @media only screen and (max-width: 600px) {
        padding: 0;
        margin: 0 !important;
        margin-right: auto;
        margin-top: 260px !important;
        margin-left: -15em !important;
      }

      .__slides {
        opacity: 0;
      }

      .__content {
        height: 180px;
        display: flex;
        align-items: center;
        // border: solid 2px red;

        @media only screen and (max-width: 600px) {
          height: 250px;
        }
      }

      & p {
        color: rgba(255, 255, 255, 0.9);

        @media only screen and (max-width: 600px) {
          width: 280px;
        }
      }

      & h3 {
        @media only screen and (max-width: 500px) {
          // position: absolute;
          // bottom: 6em;
        }
      }

      .__author {
        margin-bottom: 19px;
      }

      .__slider_btn {
        display: flex;
        justify-content: space-between;
        width: 120px;
        position: absolute;

        & img {
          cursor: pointer;

          &:hover {
            filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
              brightness(100%) contrast(85%);
          }

          &:active {
            opacity: 0;
          }

          @media only screen and (max-width: 500px) {
            border: solid 1.5px #fff;
            border-radius: 4px;
            padding: 10px;

            &:active {
              opacity: 0.5;
            }
          }
        }

        & span {
          font-family: "Plus Jakarta Sans";
        }

        .__dash {
          color: #fff;
        }

        @media only screen and (max-width: 500px) {
          align-items: center;
          width: 200px;
        }
      }
    }
  }

  @media only screen and (max-width: 1320px) {
    height: 400px;
  }

  @media only screen and (max-width: 600px) {
    height: 550px;
  }

  @media only screen and (max-width: 930px) {
    margin-top: 300px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 380px;
  }
}

.__active {
  opacity: 1;
  transition: 0.5s ease;
  transform: scale(1.01);
}
