.__banner {
  width: 200px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 25px;

  .__container {
    & p {
      margin-bottom: 8px;
    }
  }

  .__right_para {
    width: 330px;
    padding-top: 1em;
  }

  @media only screen and (max-width: 1300px) {
    padding-left: 40px;
    padding-right: 30px;
  }
}
