.__overlap {
  width: 100%;
  min-height: 300px;
  //   height: 100%;
  // padding-top: 100px;
  display: flex;
  justify-content: space-between;
  position: relative;
  // align-items: center;
  // padding-right: 80px;

  .__left {
    position: relative;
    order: 2;

    .__slider_container {
      width: 630px;
      height: 360px;
      overflow: hidden;
      display: flex;
      position: relative;
      border-radius: 8px;
      // margin-top: 3em;
      transform: translateZ(0);
      margin-top: 0;

      @media only screen and (max-width: 1200px) {
        width: 520px;
        height: 250px;
        margin-top: 5em;
      }

      @media only screen and (max-width: 1000px) {
        width: 450px;
        // height:;
      }

      @media only screen and (max-width: 930px) {
        margin-top: 3em;
      }

      @media only screen and (max-width: 530px) {
        width: 80vw;
      }

      & img {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: 0.5s ease;
        object-fit: cover;
      }
    }

    .__slider_btn {
      display: flex;
      justify-content: space-between;
      width: 120px;
      padding-top: 0.5em;
      margin: 0 auto;

      & img {
        cursor: pointer;
        filter: saturate(100%) brightness(0%);
        transition: 0.1s;

        &:hover {
          filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
            brightness(100%) contrast(85%);
        }

        &:active {
          opacity: 0;
        }

        @media only screen and (max-width: 500px) {
          border: solid 1px black;
          border-radius: 4px;
          padding: 10px;

          &:active {
            // background-color: #000;
          }
        }
      }

      & span {
        font-family: "Plus Jakarta Sans";
      }

      .__dash {
        color: #000;
      }

      @media only screen and (max-width: 500px) {
        width: 50%;
        align-items: center;
      }
    }

    // .__text_img {
    //   position: absolute;
    //   bottom: 10px;
    //   right: 10px;
    //   text-align: center;
    //   text-align: right;

    //   & h1 {
    //     font-size: 100px;
    //     color: #fff;
    //     margin-bottom: -15px;
    //   }

    //   & p {
    //     color: #fff;
    //     width: 105px;
    //     text-align: right;
    //     padding: 0 7px 7px 0;
    //   }
    // }
  }

  .__right {
    @media only screen and (max-width: 600px) {
      justify-content: flex-start;
      text-align: left;
      margin-right: auto;
    }

    h4 {
      width: 280px;
      margin-top: 19px;

      @media only screen and (max-width: 930px) {
        width: 520px;
      }
    }

    & p {
      margin-top: 16px;
      width: 342px;
      @media only screen and (max-width: 930px) {
        width: 530px;
      }

      @media only screen and (max-width: 600px) {
        width: 80vw;
        padding-right: 10px;
      }
    }

    .__dropdown_container {
      padding-top: 23px;

      .__dropdown {
        width: 328px;
        margin-bottom: 10px;

        @media only screen and (max-width: 450px) {
          width: 80vw;
          padding-right: 50px;
        }

        &:last-child {
          margin: 0;
        }
        .__head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            color: rgba(118, 104, 57, 1);
          }

          &:hover > span {
            animation: jump 0.5s;
          }

          &:active {
            opacity: 0;
          }

          @keyframes jump {
            from {
              transform: translateY(-5px);
            }
            to {
              transform: translateY(0px);
            }
          }

          .__head_text {
            font-size: 14px;
          }

          & span {
            color: rgba(118, 104, 57, 1);
            font-family: "Plus Jakarta Sans";
            // margin-bottom: 2.5px;
            margin-top: 14px;
          }
        }

        .__divider {
          width: 328px;
          height: 1px;
          // background-color: rgba(0, 0, 0, 0.093);
          background-color: rgba(118, 104, 57, 1);
          opacity: 0.3;

          margin-top: 10px;

          @media only screen and (max-width: 450px) {
            width: 70vw;
            // padding-right: 50px;
          }
        }

        .__content {
          width: 316px;
          padding: 0 10px;
          font-size: 12px;
          transition: 0.5s ease;
          overflow: hidden;
          margin-top: 10px;

          @media only screen and (max-width: 450px) {
            width: 70vw;
            // padding-right: 50px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media only screen and (max-width: 930px) {
    flex-direction: column;
    align-items: center !important;
    // border: solid 2px red;
  }

  @media only screen and (max-width: 600px) {
    // align-items: flex-start !important;
    margin-top: 50px;
  }
}

.__show {
  // display: block;
  opacity: 1;
  transform: translateY(10px);
  margin-bottom: 30px;
}

.__hide {
  opacity: 0;
  height: 0;
  transform: translateY(-10px);
  pointer-events: none;
}

.__show_img {
  opacity: 1;
  transform: scale(1.1);
}

.__hide_img {
  opacity: 0;
  // transform: scale(0);
}
