.__contact {
  height: 700px;
  width: 100%;
  font-size: 50px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding-top: 100px;

  .__form {
    width: 500px;

    .__captcha_sendbtn_section {
      display: inline-flex;

      .__send_btn {
        padding-top: 10px;
        padding-right: 10px;
      }

      @media only screen and (max-width: 600px) {
        display: grid;

        .__send_btn {
          padding-bottom: 10px;
        }
      }

    }

    & h4 {
      margin-bottom: 16px;
    }

    .__mail {
      font-size: 16px;
      text-decoration: underline;
      margin: 16px 0 32px 0;
      padding-top: 2.8px;

      & a {
        // opacity: 0;
        color: #000;
        font-weight: 400;

        &:hover {
          color: var(--primary-color);
        }

        &:active {
          opacity: 0.5;
        }
      }
    }

    .__success_message {
      color: green;
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: -1.3em;

      & img {
        width: 15px;
        margin-left: 10px;
      }
    }

    .__error_form {
      color: red;
      display: flex;
      align-items: center;
      position: absolute;
      margin-top: -1.3em;

      & img {
        width: 15px;
        margin-left: 10px;
      }
    }

    .__form_wrapper {
      margin-top: 70px;

      .__input_group {
        transition: all 0.3s ease;

        & label {
          font-size: 16px;
          opacity: 0.5;
          font-family: "Plus Jakarta Sans";
          transform: translateY(-4.8em);
          position: absolute;
          transform-origin: 0 0;
          transition: all 0.3s ease;
        }
      }
    }

    & input {
      display: block;
      width: 100%;
      background-color: transparent;
      border: none;
      border-bottom: solid 1px #000;
      padding-bottom: 4px;
      margin-bottom: 52px;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      // transition: all 0.3s ease;

      &:focus-within {
        // transform: scale(1, 1);
        // padding: 7px 10px;
        border-width: 1.5px;
      }

      &:focus+.__form_label,
      &:not(:placeholder-shown)+.__form_label {
        transform: translateY(-6.2em) scale(0.8);
      }

      &::placeholder {
        color: transparent;
      }
    }

    .__textarea_group {
      & textarea {
        &::placeholder {
          color: transparent;
        }

        &:focus-within {
          border-width: 1.5px;
        }

        &:focus+.__textarea_label,
        &:not(:placeholder-shown)+.__textarea_label {
          transform: translateY(-7.5em) scale(0.8);
        }
      }

      & label {
        font-size: 16px;
        opacity: 0.5;
        font-family: "Plus Jakarta Sans";
        transform: translateY(-6.25em);
        position: absolute;
        transform-origin: 0 0;
        transition: all 0.3s ease;
      }
    }

    .__error_message {
      font-size: 11px;
      font-family: "Plus Jakarta Sans";
      color: red;
      position: absolute;
      margin-top: -4.5em;
    }

    .__error_textarea {
      margin-top: -2.5em;
    }

    .__error_captcha {
      margin-top: -1.2em;
    }

    & textarea {
      outline-color: #44545c;

      display: block;
      background-color: transparent;
      border: none;
      width: 100%;
      height: 70px;
      border-bottom: solid 1px #000;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      margin-bottom: 30px;
      border-radius: 0;
      outline: none;
    }

    & button {
      background-color: transparent;
      border: solid 1px rgba(46, 67, 81, 1);
      border-radius: 0;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      padding: 0;
      width: 160px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 10px;
      font-size: 16px;
      color: rgba(46, 67, 81, 1);

      &:hover {
        color: rgba(118, 104, 57, 1);
      }
    }

    @media only screen and (min-width: 931px) and (max-width: 1200px) {
      width: 40vw;
      padding-left: 60px;
    }

    @media only screen and (max-width: 930px) {
      margin-top: 100px;
      padding-bottom: 90px;
      width: 500px;
    }

    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  @media only screen and (max-width: 930px) {
    flex-direction: column !important;
    height: 900px;
    padding-top: 0;
    margin-botom: 0;
    align-items: center;
  }

  @media only screen and (max-width: 1400px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (max-width: 600px) {
    // align-items: flex-start;
    padding-top: 60px;
  }

  @media only screen and (min-width: 600px) and (max-width: 930px) {
    padding-top: 60px;
  }
}