.__button {
  background-color: var(--btn-color);
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
  padding: 16px 40px;
  // text-transform: uppercase;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: translateY(-3px);
    color: rgba(118, 104, 57, 1);
  }

  &:active {
    opacity: 0.5;
  }
}
