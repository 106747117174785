.__scroll_top {
  background-color: rgba(68, 84, 92, 1);
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: absolute;
  right: 8px;
  cursor: pointer;
  margin-top: -2.2em;
  transition: 0.2s;

  &:hover > img {
    filter: invert(38%) sepia(14%) saturate(1378%) hue-rotate(9deg)
      brightness(100%) contrast(85%);
  }

  &:hover {
    transform: translateY(-3px);
  }
}
