.__map {
  width: 500px;
  // height: 500px;
  overflow: hidden;
  min-height: 500px;

  & h4 {
    // text-align: center;
    padding-bottom: 20px;

    @media only screen and (max-width: 930px) {
      text-align: left;
      padding-bottom: 25px;
    }
  }

  .__access_wrapper {
    display: flex;
    justify-content: space-between;

    & p {
      margin-bottom: 10px;

      & a {
        display: none;
        color: #000;
        margin-bottom: 20px;
        display: none;

        &:hover {
          color: var(--primary-color);
        }

        &:active {
          opacity: 0.5;
        }
      }
    }

    .__links {
      display: flex;
      flex-direction: column;
      // margin-left: 30px;
      padding-bottom: 25px;
      & a {
        color: #000;
        margin-bottom: 10px;

        &:hover {
          color: var(--primary-color);
        }

        &:active {
          opacity: 0.5;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 80vw;

    .__access_wrapper {
      & p {
        margin-bottom: 10px;
        & a {
          display: block;
        }
      }
    }

    .__links {
      & a {
        display: none;
      }
    }
  }
}
