.__divider {
  height: 1px;
  background-color: #766839;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .__container {
    width: 1400px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .__circle {
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background-color: #766839;

      @media only screen and (min-width: 600px) {
        width: 10px;
        height: 10px;
      }
    }

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    @media only screen and (max-width: 930px) {
    }

    @media only screen and (max-width: 350px) {
      height: 100vh !important;
    }
  }
  @media only screen and (max-width: 930px) {
    transform: rotate(90deg) !important;
    width: 400px !important;
    position: absolute !important;
    left: -5em !important;
    right: inherit !important;
    margin-left: inherit !important ;
    margin-right: inherit !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 14.5em;
  }

  @media only screen and (max-width: 600px) {
    left: -11em !important;
    margin-top: 15em;
  }
}
