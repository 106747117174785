.__story {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 155px;
  position: relative;

  .__left {
    padding-right: 18.2em;
    // margin-top: -10em;
    margin-bottom: 30em;
    margin-top: 4em;
    margin-left: 6.2em;

    @media only screen and (min-width: 1200px) {
      margin-left: 9em;
    }

    @media only screen and (max-width: 450px) {
      padding-left: 50px !important;
      padding-right: 14em !important;
    }

    & img {
      position: absolute;
      border-radius: 8px;
      object-fit: cover;
    }
    .__img_1 {
      width: 225px;
      z-index: 100;

      @media only screen and (max-width: 1200px) {
        width: 185px;
      }
      @media only screen and (max-width: 450px) {
        width: 155px;
        margin-left: -2em;
      }
    }
    .__img_2 {
      width: 154px;
      margin-top: -2em;
      margin-left: 11.8em;

      @media only screen and (min-width: 1200px) {
        margin-left: 13em;
      }

      @media only screen and (max-width: 1200px) {
        width: 114px;
        margin-left: 10em;
      }

      @media only screen and (max-width: 450px) {
        width: 85px;
        margin-left: 4.5em;
      }
    }
    .__img_3 {
      width: 160px;
      margin-left: -6.2em;
      margin-top: -4.6em;

      @media only screen and (min-width: 1200px) {
        width: 200px;
        margin-left: -8.7em;
        margin-top: -6.3em;
      }

      @media only screen and (max-width: 1200px) {
        width: 120px;
        margin-top: -3em;
      }
      @media only screen and (max-width: 450px) {
        width: 90px;
      }
    }
  }

  .__right {
    // margin-left: 82px;
    margin-top: -15em;
    width: 530px;

    @media only screen and (min-width: 931px) and (max-width: 1100px) {
      padding: 30px;
    }

    @media only screen and (max-width: 600px) {
      margin-right: auto;
    }

    @media only screen and (max-width: 930px) {
      margin-top: -10em;
    }

    @media only screen and (max-width: 600px) {
      width: 90%;
    }
    @media only screen and (max-width: 450px) {
      margin-top: -13em;
    }

    & h4 {
      margin: 16px 0 50px 0;
      // width: 270px;
      width: 100%;
    }

    .__text {
      margin-bottom: 10px;
      // width: 257px;
      // border: solid 2px red;
    }

    .__punchline {
      margin-top: 50px;
      font-size: 18px;
    }

    .__underline {
      width: 82px;
      height: 2px;
      background-color: #000;
      margin-top: 3px;
    }
  }
  @media only screen and (max-width: 930px) {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 1400px) {
    padding-right: 50px;
    padding-left: 50px;
  }
}
