.__container {
  width: 80vw;
  margin: 50px auto 0 auto;

  .__head {
    padding-bottom: 30px;

    & h2 {
      padding-bottom: 10px;
    }

    & p {
      padding-right: 5em;
    }
  }

  & hr {
    margin-bottom: 50px;
    opacity: 0.5;
    margin-left: -100%;
  }

  .__section {
    margin-bottom: 50px;

    & a {
      color: #000;

      &:hover {
        color: var(--primary-color);
      }
    }

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      margin-bottom: 10px;
      padding-right: 5em;
    }
  }

  @media only screen and (max-width: 500px) {
    margin: 0;
  }
}
