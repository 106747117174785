@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
//  COLORS

:root {
  --main-bg: #e5e5e5;
  --primary-color: #766839;
  --secondary-color: #2e4351;
  --neutral-color: #34372e;
  --gradient-bg: linear-gradient(267.43deg, #766839 -11.76%, #2e4351 115.18%);
  --gradient-bg-second: linear-gradient(
    267.38deg,
    rgba(118, 104, 57, 0) -13.35%,
    #2e4351 106.58%
  );
  --main-text: #fff;
  --secondary-text: rgba(46, 67, 81, 1);
  --third-text: #bbb39c;
  --fourth-text: rgba(26, 27, 23, 1);
  --btn-color: #f1f0eb;
}

.primary-one {
  color: var(--primary-color);
  background: rgba(0, 0, 0, 0.5);
}
.primary-second {
  color: var(--primary-color);
  background: rgba(255, 255, 255, 0.5);
}
.primary-third {
  color: var(--primary-color);
  background: rgba(255, 255, 255, 0.9);
}

.secondary-one {
  color: var(--secondary-color);
  background: rgba(0, 0, 0, 0.5);
}
.secondary-second {
  color: var(--secondary-color);
  background: rgba(255, 255, 255, 0.5);
}
.secondary-third {
  color: var(--secondary-color);
  background: rgba(255, 255, 255, 0.9);
}

.neutral-one {
  color: var(--neutral-color);
  background: rgba(0, 0, 0, 0.5);
}
.neutral-second {
  color: var(--neutral-color);
  background: rgba(255, 255, 255, 0.5);
}
.neutral-third {
  color: var(--neutral-color);
  background: rgba(255, 255, 255, 0.9);
}

// GLOBAL

body {
  background-color: var(--main-bg);
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_container {
  max-width: 1200px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4 {
  font-family: "Inria Serif";
  font-weight: 400;
}

h6,
h5 {
  font-family: "Plus Jakarta Sans";
}

// TYPOGRAPHY

.h1 {
  font-size: 66px;
  line-height: 64px;
  font-weight: 400;
}
.h2 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 400;
}
.h3 {
  font-size: 38px;
  line-height: 43px;
  font-weight: 400;
}
.h4 {
  font-size: 34px;
  line-height: 38px;
  font-weight: 400;
}

.h5 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 500;
}
.h6 {
  font-size: 22px;
  line-height: 25px;
  font-weight: 500;
}

.main_para {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
}

.light_para {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
}

.regular_para {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "Plus Jakarta Sans";
}
