.__study {
  background-color: #44545c;
  width: 100vw;
  height: 500px;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 100px;

  .__container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;

    & img {
      width: 300px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(87deg)
        brightness(101%) contrast(102%);
    }

    .__right {
      & h4 {
        margin-bottom: 16px;
      }

      & p {
        font-family: "Inria Serif";
        color: rgba(255, 255, 255, 0.718);
        width: 490px;
      }

      .__case_studies {
        color: #fff;
        font-family: "Plus Jakarta Sans";
        margin-top: 38px;
        text-transform: uppercase;
      }

      .__underline {
        width: 30px;
        height: 2px;
        background-color: #fff;
        margin-top: 3px;
      }

      .__tabs {
        margin-top: 1em;
        padding-bottom: 38px;
        & button {
          font-size: 12px;
          padding: 10px;
          margin-right: 1em;
          transition: background-color 0.5s ease, color 0.5s ease,
            transform 0.2s, opacity 0.2s, color 0s;
          cursor: pointer;
          background-color: transparent;
          color: #fff;
          border: solid 1px #fff;

          &:active {
            opacity: 0.5;
          }
        }
      }

      .__lists {
        margin-left: -0.9em;
        & ul {
          list-style-type: none;
          text-align: left;
          width: 550px;

          > li {
            margin-bottom: 13px;
            padding-left: 16px;
            color: rgba(255, 255, 255, 0.718);
            transition: all 1s ease;
            font-family: "Inria Serif";
            font-size: 16px;
          }

          li::before {
            content: "•";
            color: #fff;
            margin-right: 16px;
            font-size: 16px;
          }
        }
        .__col_2 {
          columns: 2;
        }
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .__container {
      // padding-left: 50px;
      // padding-right: 50px;

      & img {
        padding-left: 50px;
      }
    }
  }

  @media only screen and (max-width: 930px) {
    height: 1000px;

    & img {
      // margin-bottom: 50px;
      order: 2;
      margin-top: 14em;
      padding-left: 0 !important;
    }
    .__container {
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding-top: 100px;
      padding-bottom: 500px;

      .__right {
        // margin-top: 50px;
        width: 500px;
        text-align: center;

        & p {
          text-align: center;
        }

        .__case_studies {
          text-align: center;
          text-decoration: underline;
        }

        .__underline {
          display: none;
        }

        .__tabs {
          margin-bottom: 50px;
        }

        .__lists {
          width: 200px;
          margin-left: 10em;
          position: absolute;

          .__col_2 {
            columns: 2;
            margin-left: -10em;

            > li {
              padding-left: 0 !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    height: 1080px;
    .__container {
      & img {
        text-align: center;
        margin-top: 20em;
        // width: 70%;
        // margin-left: 2.9em;
      }

      .__right {
        text-align: left;
        padding-left: 50px;
        width: auto;
        margin-top: -3em;

        & p {
          text-align: left;
          width: 80vw;
        }

        .__case_studies {
          text-align: left;
          text-decoration: none;
        }

        .__underline {
          display: block;
        }

        .__lists {
          & ul {
            margin-left: -11em;
          }

          .__col_2 {
            columns: 1;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 370px) {
    & img {
      width: 90%;
    }
  }

  @media only screen and (min-width: 931px) and (max-width: 1050px) {
    .__right {
      padding-left: 50px;
    }
  }
}

.__hide {
  opacity: 0;
  right: -100%;
  position: absolute;

  @media only screen and (max-width: 930px) {
    right: -200vw;
  }
}

.__show {
  opacity: 1;
  position: inherit;
}

.__active {
  background-color: #fff !important;
  color: #000 !important;
}
